import React, { useEffect } from "react";
import Router from "next/router";
import { useSelector, useDispatch } from "react-redux";
import Card from "./Card";
import ProductCard from "../../components/common/ProductCard";
import ProductCardList from "../../components/common/ProductCard/product-card-list";
import { recentProductsGetRequest } from "../../redux/actions/common.action";

const RecentProducts = props => {
  const recentProducts = useSelector(({ common }) => common.recentProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!recentProducts.products.length) {
      dispatch(recentProductsGetRequest({ limit: 6 }));
    }
  }, []);

  if (!recentProducts.products.length) {
    return null;
  }

  return (
    <Card
      title="Sản phẩm đã xem"
      headerAction={{
        content: "Xem tất cả",
        onAction: () => Router.push("/product/recent-products"),
      }}
    >
      <ProductCardList>
        {recentProducts.products.map(product => (
          <ProductCard key={product.id} {...product} showVipBusinessLabel />
        ))}
      </ProductCardList>
    </Card>
  );
};

export default React.memo(RecentProducts);
